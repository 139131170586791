import Cookies from "js-cookie";
import { get, isEmpty, map, merge } from "lodash";

const CLIENT_ID_COOKIE = "smclient";

const SESSION_STORAGE_NAME = "sm_events";

const API_URL = "https://www.salesmanago.pl/api/";

/**
 * Creates request enpoint url by joining API_URL and endpoint value
 * @param {*} endpoint suffix added to API_URL
 */
const buildUrl = (endpoint) => {
  return API_URL + endpoint;
};

/**
 * Create JSON body for request. Appends user data to required request data
 * @param {*} data body data to send
 */
const buildBody = (data) =>
  JSON.stringify({
    ...{
      clientId: process.env.NEXT_PUBLIC_SM_CLIENT_ID,
      apiKey: process.env.NEXT_PUBLIC_SM_API_KEY,
      requestTime: new Date().getTime(),
      sha: process.env.NEXT_PUBLIC_SM_SHA,
      async: true,
      owner: process.env.NEXT_PUBLIC_SM_OWNER,
    },
    ...data,
  });

/**
 * Make request to SalesManago
 * @param {*} endpoint suffix added to API_URL
 * @param {*} data body data to send
 * @param {*} options fetch options
 */
export const makeRequest = async (endpoint, data, options = {}) => {
  if (isEmpty(endpoint) || isEmpty(data)) {
    return null;
  }

  const reqOptions = {
    ...{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: buildBody(data),
    },
    ...options,
  };

  return await fetch(buildUrl(endpoint), reqOptions)
    .then((res) => res.json())
    .catch((res) => {});
};

const SalesManago = {
  isActive: () => {
    return (
      !isEmpty(process.env.NEXT_PUBLIC_SM_CLIENT_ID) &&
      !isEmpty(process.env.NEXT_PUBLIC_SM_API_KEY) &&
      !isEmpty(process.env.NEXT_PUBLIC_SM_SHA) &&
      !isEmpty(process.env.NEXT_PUBLIC_SM_OWNER)
    );
  },
  getContact: function (data = null) {
    const contact = {};

    const cookieContactId = Cookies.get(CLIENT_ID_COOKIE);
    if (!isEmpty(cookieContactId) && cookieContactId !== "null") {
      contact.contactId = cookieContactId;
      return contact;
    }

    const contactEmail = get(data, "email");
    if (!isEmpty(contactEmail)) {
      delete contact.contactId;
      contact.email = contactEmail;

      return contact;
    }

    return contact;
  },
  clearEventsFromSessionStorage: async function () {
    if (typeof sessionStorage !== undefined) {
      sessionStorage.removeItem(SESSION_STORAGE_NAME);
    }
  },
  contactInit: async function (email = null) {
    if (!this.isActive() && isEmpty(email)) {
      return;
    }

    const reqBody = {
      contact: { email },
    };

    const userResponse = await this.fire("contact/upsert", reqBody);
    this.contactCookieCreate(get(userResponse, "contactId"));
  },
  contactCookieCreate: function (contactId) {
    Cookies.set(CLIENT_ID_COOKIE, contactId);
  },
  contactCookieDestroy: function () {
    Cookies.remove(CLIENT_ID_COOKIE);
  },
  addToMailingList: async function (email = null) {
    if (!this.isActive() || isEmpty(email)) {
      return;
    }

    const contact = this.getContact();
    const reqBody = {
      ...{ email },
      ...contact,
    };

    return await this.fire("contact/optin", reqBody);
  },
  removeFromMailingList: async function (emails = null) {
    if (!this.isActive() || isEmpty(emails)) {
      return;
    }

    return await this.fire("contact/batchoptout", { emails });
  },
  addToPhoneList: async function (email = null) {
    if (!this.isActive() || isEmpty(email)) {
      return;
    }

    return await this.fire("contact/phoneoptin", { email });
  },
  removeFromPhoneList: async function (email = null) {
    if (!this.isActive() || isEmpty(email)) {
      return;
    }

    return await this.fire("contact/phoneoptout", { email });
  },
  contactUpsert: async function (data) {
    if (!this.isActive()) {
      return;
    }
    const contactBody = {
      contact: {
        name:
          data?.name && data?.surname
            ? `${get(data, "name")} ${get(data, "surname")}`
            : "",
        email: get(data, "email"),
        phone: get(data, "phone", ""),
        company: get(data, "companyName", ""),
        state: "UNKNOWN",
        lang: "sk",
        address: {
          streetAddress: get(data, "street", ""),
          zipCode: get(data, "zip", ""),
          city: get(data, "city", ""),
          country: get(data, "country", ""),
        },
      },
      forceOptIn: Boolean(get(data, "agreementAccepted", false)),
      forcePhoneOptIn: Boolean(get(data, "agreementAccepted", false)),
      useApiDoubleOptIn: false,
      apiDoubleOptInEmailTemplateId: null,
      apiDoubleOptInEmailAccountId: null,
      apiDoubleOptInEmailSubject: null,
      lang: "SK",
      consentDetails: [
        {
          consentName: "NEWSLETTER",
          consentAccept: Boolean(get(data, "agreementAccepted", false)),
          agreementDate: new Date().getTime(),
          ip: null, // ako zistit na fronte?
          optOut: true,
          consentDescriptionId: "", // zatial prazdne
        },
        // {
        //   consentName: "AUTOMATION",
        //   consentAccept: Boolean(get(data, "agreementAccepted", false)),
        //   agreementDate: new Date().getTime(),
        //   ip: "", // ako zistit na fronte?
        //   optOut: true,
        //   consentDescriptionId: "", // zatial prazdne
        // },
      ],
    };

    try {
      const userResponse = await this.fire("contact/upsert", contactBody);
      this.contactCookieCreate(get(userResponse, "contactId"));
      return userResponse;
    } catch (error) {
      // do nothing - save user/order data is priority
    }
  },
  /**
   *
   * @param {*} cart
   * @param {*} action add, remove, empty
   * @returns
   */
  checkout: async function (cart = null, action = "") {
    const cartItems = get(cart, "items");

    if (!this.isActive() || (isEmpty(cartItems) && action !== "empty")) {
      return;
    }
    const cartBody = {
      contactEvent: {
        description: map(cartItems, "product.name").join(","),
        products: map(cartItems, "product.id").join(","),
        value: get(cart, "sumData.price"),
        contactExtEventType: "CART",
        detail1: action,
        detail2: typeof window !== undefined ? window?.location?.href : "",
        externalId: `cart`,
      },
    };
    return this.addContactExtEvent(cartBody);
  },
  addContactExtEvent: async function (data) {
    const contact = this.getContact(data);
    if (!this.isActive() || isEmpty(contact)) {
      return;
    }

    const defaultEventObject = {
      contactEvent: {
        date: new Date().getTime(),
        products: "",
        location: "www.bigon.sk",
        value: "",
        contactExtEventType: "",
        detail1: "",
        detail2: "",
        detail3: "",
        detail4: "",
        detail5: "",
        detail6: "",
        detail7: "",
        externalId: "",
        shopDomain: "bigon.sk",
      },
    };

    const reqBody = merge(contact, defaultEventObject, data);

    let eventMapKey = "";
    if (data?.contactEvent?.externalId) {
      eventMapKey = `contactExtEvent-${data?.contactEvent?.externalId}`;
    }

    let eventId = "";
    let eventsJson = {};
    if (typeof sessionStorage !== undefined) {
      const events = sessionStorage.getItem(SESSION_STORAGE_NAME);
      eventsJson = events ? JSON.parse(events) : {};

      eventId = eventsJson?.[eventMapKey] || "";
    }

    if (eventId) {
      reqBody.contactEvent.eventId = eventId;
    }

    let endpoint = "v2/contact/addContactExtEvent";
    if (eventId) {
      endpoint = "v2/contact/updateContactExtEvent";
    }

    try {
      const response = await this.fire(endpoint, reqBody);

      if (
        !isEmpty(response) &&
        typeof sessionStorage !== undefined &&
        response?.eventId
      ) {
        sessionStorage.setItem(
          SESSION_STORAGE_NAME,
          JSON.stringify({ ...eventsJson, [eventMapKey]: response?.eventId })
        );
      }

      return response;
    } catch (error) {
      // do nothing - save user/order data is priority
    }
  },
  addContactPurchaseEvent: async function (order) {
    if (!this.isActive()) {
      return;
    }
    const getOrderStatus = (type = 0) => {
      switch (type) {
        case 3:
          return "cancelled";
        default:
          return "new";
      }
    };

    const orderStatus = getOrderStatus(get(order, "orderStatus.type"));

    const contact = this.getContact(order);
    const reqBody = {
      ...contact,
      contactEvent: {
        date: new Date().getTime(),
        description: map(get(order, "orderItems"), (item) =>
          get(item, "product.name")
        ).join(","),
        products: map(get(order, "orderItems"), (item) =>
          get(item, "productId")
        ).join(","),
        location: "www.bigon.sk",
        value: get(order, "totalSumData.price"),
        contactExtEventType: "PURCHASE",
        detail1: orderStatus,
        detail2: get(order, "payment"),
        detail3: get(order, "delivery"),
        shopDomain: "bigon.sk",
        externalId: `order-${get(order, "id")}`,
      },
    };

    let endpoint = "v2/contact/addContactExtEvent";
    if (orderStatus !== "new") {
      endpoint = "v2/contact/updateContactExtEvent";
    }

    try {
      return await this.fire(endpoint, reqBody);
    } catch (error) {
      // do nothing - save user/order data is priority
    }
  },
  purchase: async function (order) {
    try {
      const user = await this.contactUpsert(order);
      if (user?.success) {
        this.addContactPurchaseEvent(order);
        this.clearEventsFromSessionStorage();
      }
    } catch (error) {
      // do nothing - save user/order data is priority
    }
  },
  fire: async (action, data) => {
    if (isEmpty(action) || isEmpty(data)) {
      return {};
    }

    const response = await fetch(`/api/pages/salesmanago`, {
      method: "POST",
      body: JSON.stringify({ action, data }),
    }).then((data) => data.json());

    if (response) {
      return response;
    }

    return {};
  },
};

export default SalesManago;
